<template>
  <Button class="cart-button"
          type="secondary"
          icon="shopping-basket-2-line"
          @click="goToCheckout">
    <div>
      {{ t('buttons.basket') }}

      <div v-if="itemAmountInBasket > 0" class="cart-button__notification">
        {{ itemAmountInBasket }}
      </div>
    </div>
  </Button>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import Button from '@/components/partials/Buttons/Button/Button.vue'

const { t } = useI18n()
const store = useStore()
const router = useRouter()
const { localizedRoute } = useLocalizedRouter()

async function goToCheckout() {
  await router.push(localizedRoute({ name: 'checkout' }))
}

const itemAmountInBasket = computed(() => store.getters['cart/basketItems']?.count() ?? 0)
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';

.cart-button {
  position: relative;

  &__notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    @include gradient-button;
    font-size: 0.7rem;
    color: #fff;
    line-height: 100%;
  }
}
</style>
