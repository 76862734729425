<template>
  <Button v-if="userLoggedIn"
          type="secondary"
          icon="user-3-line"
          @click="accountButtonRedirect">
  </Button>
  <Button v-else
          type="secondary"
          @click="accountButtonRedirect">
    {{ t('buttons.log-in') }}
  </Button>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '@/stores'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import { LOGIN_MODAL } from '@/store/modals'
import { useLocalizedRouter } from '@/composables/localizedRouter'

const store = useStore()
const router = useRouter()
const { t } = useI18n()
const { localizedRoute } = useLocalizedRouter()

const userLoggedIn = computed(() => store.getters['user/loggedIn'])

async function accountButtonRedirect() {
  if (userLoggedIn.value) {
    if (router.currentRoute.value.name.startsWith('customer-account-orders')) {
      return
    }

    await router.push(localizedRoute({ name: 'customer-account-orders' }))
  } else {
    await store.dispatch('modals/toggle', {
      modal: LOGIN_MODAL,
      open: true,
    })
  }
}
</script>
