<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'
import { ClientOnly } from 'vite-plugin-vue-ssr'
import { useDebounceFn } from '@vueuse/core'
import SearchModal from '@/components/Modals/SearchModal/SearchModal.vue'
import SearchInput from '@/components/Search/SearchInput.vue'
import CartButton from './redesign/CartButton.vue'
import UserAccountButton from '@/components/Buttons/UserAccountButton/UserAccountButtonRedesign.vue'
import MenuTooltip from '@/components/Menu/MenuTooltip.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Tooltip from '@/components/partials/Tooltip.vue'
import LanguageSwitcherCircle from '@/pages/home/components/Navigation/LanguageSwitcherCircle.vue'

type Props = {
  logoLink: object | string
}

const props = defineProps<Props>()

const { t } = useI18n()
const store = useStore()

const state = reactive({
  searchModalOpen: false,
})

const route = useRoute()

const hasSearch = computed(() => store.getters['marketplace/searchQuery'] !== null)

const showSearch = computed(() => route.name.startsWith('marketplace'))

const query = computed(() => store.getters['marketplace/searchQuery'])

const updateSearchQuery = useDebounceFn(async (query) => {
  await store.dispatch('marketplace/storeSearchQuery', query === '' ? null : query)
}, 500)

const showCartButton = computed(() =>
  !route.name.includes('thanks')
  && !route.name.includes('checkout')
  && !route.name.includes('establishment')
)

const showMenu = computed(() => !route.name.includes('checkout'))

const fluid = route.name.startsWith('marketplace') || route.name.startsWith('slug')

const router = useRouter()

async function openLink(event) {
  event.preventDefault()

  if (typeof props.logoLink === 'string') {
    window.location.href = props.logoLink
  } else {
    await router.push(props.logoLink)
  }
}
</script>

<template>
  <nav class="container nav nav--pidgey__b"
       :class="{ 'container--fluid': fluid }"
       data-test-id="navbar-desktop"
  >
    <Teleport to="#teleports">
      <SearchModal :open="state.searchModalOpen"
                   @close="state.searchModalOpen = false"
      />
    </Teleport>

    <a @click="openLink"
       class="nav__logo-link"
       data-test-id="navbar-logo">
      <img class="nav__logo"
           alt="Bistroo"
           src="/assets/img/brand/bistroo-logo.svg"
           width="126"
           data-not-lazy />
    </a>

    <ul class="nav__menu">
      <li v-if="showSearch" class="nav__item">
        <SearchInput :model-value="query" @change="updateSearchQuery" fluid v-if="hasSearch" />
        <Button class="search"
                type="secondary"
                icon="search-line"
                @click="state.searchModalOpen = true"
                data-test-id="search-button"
                v-else>
          {{ t('terms.search') }}
        </Button>
      </li>
      <li v-if="showCartButton" class="nav__item">
        <ClientOnly>
          <CartButton data-test-id="cart-button" />
        </ClientOnly>
      </li>
      <li>
        <ClientOnly>
          <UserAccountButton data-test-id="user-account-button" />
        </ClientOnly>
      </li>
      <li>
        <LanguageSwitcherCircle class="desktop-language-selector" />
      </li>
      <li v-if="showMenu" class="nav__item">
        <Tooltip ref="tooltip">
          <template #default="{ active }">
            <Button type="secondary"
                    data-test-id="desktop-menu-tooltip"
                    :icon="active ? 'cross-line' : 'menu-line'" />
          </template>
          <template #content>
            <MenuTooltip @close-tooltip="() => $refs.tooltip.close()" />
          </template>
        </Tooltip>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';

.nav {
  $self: &;
  display: none;
  justify-content: space-between;

  @include lg-up {
    display: flex;
  }

  &__logo-link {
    display: flex;
    align-items: center;

    &:deep(.b-icon-icon svg) {
      width: 2.5rem;
      height: 2.5rem;
      margin-top: 0.25rem;
    }
  }

  &__logo {
    cursor: pointer;
  }

  &__menu {
    display: flex;
    margin: 0 -0.75rem;
    padding: 0;
    li{
      align-content: center;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    margin: 0 0.75rem;
    vertical-align:middle;
  }

  &:deep(.tooltip),
  &:deep(.tooltip__element) {
    display: flex;
    justify-content: center;
  }

  &--pidgey__b {
    &:deep(.button .b-icon-icon),
    &:deep(.button .b-icon-icon svg) {
      width: 1.5rem;
      height: 1.5rem;
    }
    #{$self}__menu {
      gap: 0.75rem;
    }

    #{$self}__item {
      margin: 0;
    }
  }
}

:deep(.desktop-language-selector) {
  &.button::before {
    background: linear-gradient(66deg, rgb(255, 42, 161) 0%, rgb(255, 157, 5) 100%);
  }

  .button__content {
    background: #fff;
    padding: 0.75rem;
    border: 1px solid var(--color-neutral-quaternary);

    .flag {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
