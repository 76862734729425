<template>
  <div ref="search"
       class="search-input"
       :class="{ 'search-input--highlight': state.highlight }">
    <div class="search-input__action">
      <Icon v-if="!state.loading" name="search" />
      <LoadingSpinner v-else />
    </div>
    <input ref="searchInput"
           v-model="state.query"
           class="search-input__input"
           type="text"
           @focus="state.highlight = true"
           @blur="state.highlight = !!state.query"
           @input="searchQuery"
           :placeholder="placeholder"
           data-test-id="search-input"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive, onMounted } from 'vue'
import { useStore } from '@/stores'
import { useDebounceFn } from '@vueuse/core'
import Icon from '@/components/partials/Icon.vue'
import LoadingSpinner from '@/components/partials/Loading/LoadingSpinner.vue'

type Props = {
  disabled?: boolean
  placeholder?: string
}

withDefaults(defineProps<Props>(), {
  disabled: false,
  placeholder: '',
})

const emit = defineEmits(['search'])

const store = useStore()

const query = computed(() => store.getters['marketplace/searchQuery'])

const state = reactive({
  loading: false,
  highlight: false,
  query: query.value,
})

const searchQuery = useDebounceFn(() => {
  emit('search', state.query)
}, 500)

const searchInput = ref<HTMLElement | null>(null)

onMounted(() => {
  searchInput.value?.focus()
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.search-input {
  $self: &;
  position: relative;
  width: 100%;

  &__input {
    position: relative;
    z-index: 2;
    padding: 1.25rem 1rem 1.25rem 4rem;
    margin: 0;
    border: 1px solid var(--color-neutral-quaternary);
    border-radius: 2rem;
    width: 100%;
    line-height: 1.4;
    height: 100%;
    margin-bottom: 1px; // bottom line fix

    &::placeholder {
      color: var(--color-neutral-primary);
    }
  }

  &__action {
    position: absolute;
    left: 0.45rem;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    &:deep(.b-icon-icon) {
      position: relative;
      transform: scale(1);
    }
  }

  &::before {
    @include gradient-button;

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9, 0.6);
    border-radius: 50rem;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    opacity: 0.3;
    transition: all 0.2s;
  }

  &--highlight {
    &::before {
      transform: translate(-50%, -50%) scale(1, 1);
    }
  }
}
</style>
