<template>
  <div ref="search"
       class="search"
       :class="{'search--fluid': fluid }">
    <input v-model="query"
           class="search__input"
           type="text"
           :disabled="disabled">
    <div class="search__action">
      <Icon v-if="!loadingState" name="search" />
      <LoadingSpinner v-else />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import Icon from '@/components/partials/Icon.vue'
import LoadingSpinner from '@/components/partials/Loading/LoadingSpinner.vue'
// import { PRODUCT_SEARCHED } from '@/plugins/globals/segment/handlers'

type Props = {
  modelValue: string
  disabled?: boolean
  fluid?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  fluid: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', query: string): void
  (e: 'change', query: string): void
}>()

const query = computed({
  get: () => props.modelValue,
  set: val => {
    emit('update:modelValue', val)
    emit('change', val)
  },
})

const loadingState = ref(false)
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.search {
  $self: &;
  position: relative;

  // Styling of focus ring
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    border-radius: 50rem;
    pointer-events: none;
    background: $new-gradient-brand-main;
    opacity: 0.2;
    transform: translate(-50%, -50%) scale(0.95, 0.65);
    transition: all 0.2s;
  }

  // Animating of focus ring
  &:active,
  &:hover,
  &.data-active {
    &::before {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &__input {
    position: relative;
    z-index: 2;
    padding: 0.845rem 4rem 0.845rem 1rem;
    margin: 0;
    border: 1px solid var(--color-neutral-quaternary);
    border-radius: 2rem;
    width: 100%;
    line-height: 1.4;
    height: 100%;
    margin-bottom: 1px; // bottom line fix

    @include lg-up {
      width: 335px;
      z-index: 51;
      position: relative;
    }

    &::placeholder {
      color: var(--color-neutral-primary);
    }
  }

  &__action {
    position: absolute;
    right: 0.45rem;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @include icon-svg(1.25rem, 1.25rem) {
      position: relative;
      transform: scale(1);
    }

    @include lg-up {
      z-index: 52;
    }

    // &:hover {
    //   cursor: pointer;
    //   @include icon-svg {
    //     transform: scale(1.1);
    //     transition: transform 200ms ease-in-out;
    //   }
    // }
  }

  &--fluid {
    #{$self}__input {
      @include lg-up {
        width: 100%;
      }
    }
  }
}
</style>
