<template>
  <div class="options">
    <!-- Address -->
    <div class="distribution__address"
      data-test-id="mobile-options-address"
      @click="$emit('clicked-address')"
    >
      <div class="distribution__icon-container">
        <div class="distribution__icon">
          <Icon name="home-user" />
        </div>
      </div>
      <div ref="address-value-container"
        class="distribution__text"
      >
        <div ref="address-value"
          class="distribution__value"
        >
          <slot name="address-value">
            <!-- Address line -->
          </slot>
        </div>
        <div class="distribution__label">
          {{ t('buttons.edit') }}
        </div>
      </div>
    </div>

    <!-- Distribution type -->
    <div class="distribution__type"
      data-test-id="mobile-options-distribution"
      @click="$emit('clicked-distribution-type')"
    >
      <div class="distribution__icon-container">
        <div class="distribution__icon">
          <Icon name="pin" />
        </div>
      </div>
      <div class="distribution__text">
        <div class="distribution__value">
          <slot name="dist-value">
            <!-- Dist type -->
          </slot>
        </div>
        <div class="distribution__label">
          {{ t('buttons.edit') }}
        </div>
      </div>
    </div>

    <!-- Filters btn -->
    <div class="options__filters"
      @click="$emit('clicked-filters')"
    >
      <Icon :name="hasActiveFilters ? 'filter-active' : 'filter'"
        data-test-id="mobile-filter-button"
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import Icon from '@/components/partials/Icon.vue'

export default {
  name: 'MobileOptions',

  components: { Icon },

  props: {
    hasActiveFilters: { type: Boolean, default: false },
  },

  emits: ['clicked-address', 'clicked-distribution-type', 'clicked-filters'],

  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
$icon-size: 2.5rem;

.options {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.distribution {
  &__address,
  &__type {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
    max-width: calc(50% - 1rem); // 50% - gap / 2
    overflow: hidden;
  }

  &__icon {
    width: 100%;
    height: 100%;
    width: $icon-size;
    height: $icon-size;
    background-color: #fff;
    border: 1px solid var(--color-neutral-quaternary);
    border-radius: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;

    // Center the icon
    & span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__text {
    position: relative;
    max-width: calc(100% - $icon-size - 0.5rem);
  }

  &__value,
  &__label {
    overflow: hidden;
    // height: 1.32rem; // line-height
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__value {
    font-weight: 700;
  }

  &__label {
    font-size: 0.875rem;
    margin-top: -0.2rem;
  }
}
</style>
