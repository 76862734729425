<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import UserAccountButton from './UserAccountButton/UserAccountButton.vue'
import { LOGIN_MODAL } from '@/store/modals'

defineProps({
  inline: { type: Boolean, default: false },
})

const store = useStore()
const router = useRouter()
const { localizedRoute } = useLocalizedRouter()

const userLoggedIn = computed(() => store.getters['user/loggedIn'])

async function onUserBtnClick() {
  if (userLoggedIn.value) {
    await router.push(localizedRoute({ name: 'customer-account' }))

    return
  }

  await store.dispatch('modals/toggle', {
    modal: LOGIN_MODAL,
    open: true,
  })
}
</script>

<template>
  <UserAccountButton :inline="inline"
                     :logged-in="$store.getters['user/loggedIn']"
                     @click="onUserBtnClick" />
</template>
