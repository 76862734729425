<script setup>
import Icon from '@/components/partials/Icon.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'

defineProps({
  loggedIn: { type: Boolean, default: false },
  inline: { type: Boolean, default: false },
})

const emit = defineEmits(['click'])

const redesign = true
</script>

<template>
  <Button v-if="!inline"
    class="user-account-button"
    :class="{
      'user-account-button--logged-in': loggedIn,
      'user-account-button--not-logged-in': !loggedIn,
    }"
    size="xl"
    type="secondary"
    :icon="redesign ? 'user-3-line' : 'user'"
    data-test-id="user-account-button"
    hover-style="inverted"
    @click="emit('click')"
  />
  <div v-else
    data-test-id="user-account-button"
    class="user-account-button user-account-button--inline"
    :class="{
      'user-account-button--logged-in': loggedIn,
      'user-account-button--not-logged-in': !loggedIn,
    }"
    @click="emit('click')"
  >
    <Icon class="user-account-button__icon"
      :name="redesign ? 'user-3-line' : 'user'"
    />
  </div>
</template>

<style lang="scss" scoped>
.user-account-button {
  $self: &;

  &--logged-in,
  &--not-logged-in {
    &::after {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 3;
      top: 0.7rem;
      right: -0.7rem;
      width: 0.8rem;
      height: 0.8rem;
      padding: 0.3rem 0.3rem 0.3rem 0.3rem;
      border-radius: 50%;
      background-color: var(--color-success);
      background-image: url('/assets/img/check-rounded.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 0.75rem 0.75rem;
    }
  }

  &--logged-in {
    &::after {
      background-color: var(--color-success);
      background-image: url('/assets/img/check-rounded.svg');
    }
  }

  &--not-logged-in {
    &::after {
      background-color: var(--color-neutral-quaternary);
      background-image: url('/assets/img/icons/cross-fat.svg');
    }
  }

  &--inline {
    padding: 0 !important;
    transform: scale(0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    &#{$self}--logged-in,
    &#{$self}--not-logged-in {
      &::after {
        top: 0;
        right: -0.5rem;
        width: 0.2rem;
        height: 0.2rem;
      }
    }

    &#{$self}--logged-in {
      &::after {
        background-image: none;
      }
    }

    &#{$self}--not-logged-in {
      &::after {
        display: none;
      }
    }
  }
}
</style>
